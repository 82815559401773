import React, { useState } from 'react'
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import Drawer from './drawer';
import { stringAvatar } from '../helperFunctions';
import { Avatar } from '@mui/material';

export default function Doctors({doctors, selectedDate}) {
    const [drawer, setDrawer] = useState(false);
    const [doc, setDoc] = useState({});
    console.log(doctors,'xxx')

    const handleDrawerOpen = (d) => {
        setDrawer(true);
        setDoc(d);
    };
    
    return (
        <div className="mt-4 md:mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 laptop:flex laptop:flex-wrap laptop:justify-center gap-4">
                {doctors?.map((d) => (
                    <div 
                        key={d._id} 
                        className="laptop:w-[30%] hover:border-primary border border-gray-50 rounded-lg flex px-6 py-7 gap-2 cursor-pointer"
                        onClick={() => handleDrawerOpen(d)}
                    >
                        {d?.picture ? (
                            <img src={d.picture} alt='doctor' className="rounded-full h-[65px] w-[65px] object-cover"/>
                        ) : <Avatar {...stringAvatar(d.lastName, d.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/> }
                        <div>
                            <p className="font-medium text-[16px]">{d.lastName} {d.firstName}</p>
                            <p className="font-normal text-[16px]">{d.specialization == 'Nil' ? d.cadre : d.specialization}</p>
                            {d.rating ? 
                                <div className='flex items-center gap-1'>
                                    {[...Array(Math.floor(d?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                    <p className="font-normal text-[11px]">
                                      {Number.isInteger(d.rating) ? `${d.rating}.0` : d.rating}
                                    </p>
                                </div>
                            : ''}
                        </div>
                    </div>
                ))}
            </div>
            <Drawer setDrawer={setDrawer} handleDrawerOpen={handleDrawerOpen} drawer={drawer} doc={doc} selectedDate={selectedDate} />
        </div>
    )
}