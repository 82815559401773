import React, { useEffect, useState, useContext } from "react";
import Loader from "../Loader";
import { Defualt } from "../Default";
import { AppContext } from "../../App";
import { useHomeStyles } from "./styles";
import { useQuery } from "@apollo/client";
import StartConsultation from "./Landing";
import { useParams } from "react-router-dom";
import { useFetchAPI } from "../../api/axios/axios";
import { GET_PARTNER_BY_SUBDOMAIN, DOCTOR_PROFILE } from "../../api/graphQL/query";

export const Home = () => {
  const classes = useHomeStyles();
  const { subdomain } = useParams();
  const [, setWebURL] = useState("");
  const { GET_PLANS } = useFetchAPI();
  const {
    setLoading,
    setToken,
    plans,
    setPlans,
    setPartnerInfo,
    showForm,
    setShowForm,
  } = useContext(AppContext);
  const { error, data, loading } = useQuery(GET_PARTNER_BY_SUBDOMAIN, {
    variables: { subdomain: subdomain || "" },
  });

  const providerId = data?.getPartnerBySubdomain?.providerId || "";
  localStorage.setItem("providerId",  providerId);

  const apiKey = data ? data.getPartnerBySubdomain.apiKey : "ADEJhI-F6WbPyl_XoSEK";
  localStorage.setItem("apiKey",apiKey);
  
  const { widgetColor, widgetLogo, category } =
  data?.getPartnerBySubdomain || {};
  localStorage.setItem("logo", widgetLogo);

  const handleShowForm = () => {
    const newShowForm = !showForm;
    sessionStorage.setItem("showForm", newShowForm);
    setShowForm(newShowForm);
  };

  useEffect(() => {
    if (!data) return;
    setPartnerInfo(data?.getPartnerBySubdomain);
    sessionStorage.setItem(
      "partnerInfo",
      JSON.stringify(data?.getPartnerBySubdomain)
    );
    const apiKey =  data ? data.getPartnerBySubdomain.apiKey : "ADEJhI-F6WbPyl_XoSEK";
    setToken(apiKey);
    GET_PLANS(providerId, apiKey)
      .then((res) => {
        console.log(res.data.data,'plansData')

        setPlans(res?.data?.data || []);
      })
      .catch((error) => {
        setPlans([]);
        console.error("Failed to get plans:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isLoading = loading || plans === undefined;
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, plans]);

  useEffect(() => {
    ["paymentSuccessInfo", "consultationInfo", "psRef"].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }, []);

  if (error && subdomain) {
    return (
      <Loader type="fullpage" />
    );
  }

  if (loading)
  return (
    <div className="h-screen flex justify-center items-center">
      <Loader type="fullPage"/>
    </div>
  );

  return (
    <>
      {data?.getPartnerBySubdomain?.category === null ||
      subdomain === undefined || category === "hmo" ? (
        <StartConsultation />
      ) : (
        <div className={classes.root}>
          {!showForm && (
            <Defualt
              openForm={handleShowForm}
              widgetColor={widgetColor}
              widgetLogo={widgetLogo}
              id={subdomain}
              plan={plans}
              category={category}
            />
          )}
        </div>
      )}
    </>
  );
};

